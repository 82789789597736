import React from "react"
import HeroIcon1 from "./images/healthIcon.svg"
import HeroIcon2 from "./images/healthIcon2.svg"
import {
  FlowHeaderRpm,
  FlowHeadingRpm,
} from "../rmt-section/styles/rpmWorkflowElements"
import {
  FlowCont,
  FlowInnerCont,
  FlowWrapper,
  Hr,
  Icon1,
  IconCont,
  MtmflowCard,
  MtmIconBox,
  MtmText,
  MtmWrapper,
  
} from "./styles/mtmWorkflowElements"
const mtmFlowData = [
  {
    icon: HeroIcon2,
    text: "Browse or search the platform's vast selection of medical and non-medical products from various pharmacies. Add to cart, add a prescription if necessary, and then check out. When your order is received, the pharmacy will contact you",
  },
  {
    icon: HeroIcon1,
    text: "Upload your prescription by taking a picture of it and sending it to your preferred pharmacy on the platform to receive an instant quote. Select the county and the estate you are in and you will be able to see a list of pharmacies to order from",
  },
]
export default function WorkflowMtm() {
  return (
    <FlowCont>
      <FlowInnerCont>
        <FlowHeaderRpm>
          <FlowHeadingRpm>Let's see how it works</FlowHeadingRpm>
          <Hr />
        </FlowHeaderRpm>
        <FlowWrapper>
          {mtmFlowData.map((item, index) => (
            <MtmflowCard key={index}>
              <MtmIconBox>{`0${index + 1}`}</MtmIconBox>
              <IconCont>
                <Icon1 src={item.icon} />
              </IconCont>
              <MtmWrapper>
                <MtmText>{item.text}</MtmText>
              </MtmWrapper>
            </MtmflowCard>
          ))}
        </FlowWrapper>
      </FlowInnerCont>
    </FlowCont>
  )
}


