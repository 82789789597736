export const providerDetails = {
  id: 'service',
  headline: 'Afyabook for Provider',
  description: 'Are you a healthcare provider interested in reaching new patients?',
  detailList: [
    'Reach patients in your area looking for a new provider',
    'Fill last minute openings in your schedule',
    'Strengthen your online reputation with verified reviews',
  ],
  buttonLabel: 'List your Practice on Afyabook',
  buttonLabel1: 'Read more',
};
