export const providerDetailsRider = {
  id: 'service',
  headline: 'Afyabook for Labs and Diagnostic Centers',
  description: 'Are you a laboratory or diagnostic center interested in reaching new patients?',
  detailList: [
    'Reach patients in your area looking for laboratory services',
    'Fill last minute openings in your schedule',
    'Strengthen your online reputation with verified reviews',
  ],
  buttonLabel: 'Join Afyabook community',
  buttonLabel1: 'Read more',
};
