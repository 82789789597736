import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { desktop, mobileL, mobileM, mobileS, tablet } from "../../responsive"
import { servDetails } from "./servData"
const ServCardContainer = styled.div`
  width: 250px;
  height: 200px;

  margin: 20px;
  background: #fefefe;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);

  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 38px 85px;
  cursor: pointer;
  &:hover {
    transition: all 0.4s ease-in-out;
    box-shadow: 0 3px 7px #a3d4cb;
    background: #fff;
    border: 1px;

    color: #205072;
  }
  ${mobileS({
    width: "100%",
    margin: "1em 0",
    padding: "0",
  })}
  ${mobileM({
    width: "100%",
    margin: "1em 0",
    padding: "0",
  })}
    ${tablet({ width: "17em", margin: "1em" })}
    ${desktop({ width: "300px", height: "200px", margin: "25px" })}
`
const ServCardWrapper = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  ${mobileS({
    width: "100%",
    padding: "1em",
  })}
  ${mobileM({
    width: "100%",
    padding: "1em",
  })}
  ${mobileL({
    width: "100%",
    padding: "1em",
  })}
  ${desktop({ padding: "10px" })}
`
const Label = styled.div`
  width: 121px;
  display: none;
  color: black;
  background: rgba(199, 244, 196, 0.49);
  border-radius: 5px;
  margin: 23px;
  text-align: center;
  ${mobileS({
    width: "74px",
    fontSize: "10px",
    margin: "10px",
  })}
  ${mobileM({
    width: "74px",
    fontSize: "10px",
    margin: "10px",
  })}
`
const Img = styled.img`
  width: 20%;
  height: 30%;
  ${mobileS({ width: "2.8em", height: "50px", marginBottom: "15px" })}
  ${mobileM({ width: "2.8em", height: "50px", marginBottom: "15px" })}
  ${desktop({ width: "3em", height: "65px", marginBottom: "5px" })}
`
const CardLink = styled(Link)`
  text-decoration: none;
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 40%;
  }
  @media screen and (min-width: 1024px) {
    width: 33%;
  }
  @media screen and (min-width: 1440px) {
    width: 23%;
  }
`
const Title = styled.p`
  font-family: Oswald;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
  ${mobileS({ fontSize: "1.3em", marginBottom: "5px" })}
  ${mobileM({ fontSize: "1.2em", marginBottom: "10px" })}
  ${desktop({
    marginBottom: "5px",
    fontWeight: "400",
    lineHeight: "30px",
  })}
`
const Subtitle = styled.span`
  font-family: Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.2em;
  /* identical to box height */

  text-align: left;

  color: #7c7c7c;
  margin-top: 5px;
  ${mobileS({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${mobileM({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${mobileL({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${tablet({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${desktop({
    fontSize: "1.4em",
    lineHeight: "1.1em",
    fontWeight: "300",
    color: "#7c7c7c",
  })}
`
const Line = styled.hr`
  height: 1.5px;
  width: 80%;
  background-color: #1d9682;
  margin: 0.5em;
`
export default function ServiceCard() {
  return (
    <>
      {servDetails.map((item,index) => {
        const { id, icon, link, title, subtitle } = item
        return (
          <>
            <CardLink to={link}  key={id-index}>
              <ServCardContainer>
                <ServCardWrapper>
                  <Title>{title}</Title>
                  <Line />
                  <Subtitle>{subtitle}</Subtitle>
                  <div style={{ marginTop: "1em" }}>Learn more</div>
                </ServCardWrapper>
              </ServCardContainer>
            </CardLink>
          </>
        )
      })}
    </>
  )
}
