import React from "react"
import styled from "styled-components"
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  RegBoxin,
  Counter,
  CartLink,
  AfyabookLogo,
} from "./NavBarElement"
import Logo from "../../images/Afyabook.svg"
import { FaBars } from "react-icons/fa"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined"
import SearchElement from "./searchElement"
import useAuthState from "../../stores/auth"
import useCartState from "../../stores/cart"
import { navigate } from 'gatsby'


const UploadButtonContainer = styled.div`
  display: none;

  @media (min-width: 768px) { 
    display: inline-block;
  }
`
const UploadIconStyled = styled(UploadFileOutlinedIcon)`
  font-size: 2rem !important; 
  margin-left: 600px; 

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem !important; 
    margin-left: 200px; 
  }
`
const FindDoctorButton = styled.button`
  padding: 5px 10px;
  font-size: 20px;
  background-color: #71C4DE; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; 
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const Navbar = ({ toggle }) => {
  const { isLoggedIn } = useAuthState()
  const { getCartLength } = useCartState()

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <AfyabookLogo src={Logo} />
          </NavLogo>
          {isLoggedIn() && (
            <>
              <UploadButtonContainer>
                <CartLink to="/upload-prescription">
                  <UploadIconStyled />
                </CartLink>
              </UploadButtonContainer>
              <UploadButtonContainer>
                <FindDoctorButton onClick={() => navigate(`/doctors/`)}>
                  Book Consultation
                </FindDoctorButton>
              </UploadButtonContainer>
              <RegBoxin>
                <CartLink to="/Cart">
                  <ShoppingBagOutlinedIcon
                    style={{ fontSize: '2.1rem', marginRight: '5px' }}
                  />
                  <Counter>{getCartLength()}</Counter>
                </CartLink>
              </RegBoxin>
            </>
          )}
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
        </NavbarContainer>
      </Nav>
      <div style={{ position: 'sticky', top: '60px', zIndex: '20' }}>
        <SearchElement />
      </div>
    </>
  )
}

export default Navbar
