import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaTimes } from 'react-icons/fa';
import {
  BiHome, BiLogInCircle, BiPhoneCall, BiLogOutCircle,
} from 'react-icons/bi';
import { GiMedicines } from 'react-icons/gi';
import { AiOutlineMedicineBox, AiOutlineLogin } from 'react-icons/ai';

import { MdOutlineAssignmentInd, MdOutlineTipsAndUpdates } from 'react-icons/md';
import { GrCloudSoftware } from 'react-icons/gr';

import { ImBlog } from 'react-icons/im';
import { FcAbout } from 'react-icons/fc';
import { mobileS, mobileM } from '../../responsive';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  overflow: auto;
  height: 100%;
  //background: #d1eadd;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  ${mobileS({ width: '100%' })}
  ${mobileM({ width: '100%' })}
`;
export const CloseIcon = styled(FaTimes)`
  color: #235274;
`;
export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
export const SidebarWrapper = styled.div`
  color: #d1eadd;
  @media screen and (min-width: 320px) {
    width: 100%;
    padding: 2em 0;
    background-color: white;
    align-self: start;
    justify-self: end;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  }
  @media screen and (min-width: 375px) {
    width: 90%;
  }
  @media screen and (min-width: 425px) {
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
    padding: 2em 0;
    background-color: white;
    align-self: start;
    justify-self: end;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  }

  @media screen and (min-width: 1440px) {
    width: 20%;
  }
`;
export const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  color: #86c2a6;
  &:hover {
    color: #235274;
    transition: 0.2s ease-in-out;
  }
  @media screen and (min-width: 320px) {
    border-bottom: 1px solid #86c2a6;
    width: 80%;
    align-self: center;
    justify-self: center;
    padding: 12px 0;
    justify-content: flex-start;
  }
  @media screen and (min-width: 768px) {
    border-bottom: 1px solid #86c2a6;
    width: 80%;
    align-self: center;
    justify-self: center;
    padding: 12px 0;
    justify-content: flex-start;
  }
`;
export const SidebarBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  border: none;
  outline: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  color: #86c2a6;
  background: none;
  &:hover {
    color: #235274;
    transition: 0.2s ease-in-out;
  }
  @media screen and (min-width: 320px) {
    border-bottom: 1px solid #86c2a6;
    width: 80%;
    align-self: center;
    justify-self: center;
    padding: 12px 0;
    justify-content: flex-start;
  }
  @media screen and (min-width: 768px) {
    border-bottom: 1px solid #86c2a6;
    width: 80%;
    align-self: center;
    justify-self: center;
    padding: 12px 0;
    justify-content: flex-start;
  }
`;
export const HomeIcon = styled(BiHome)`
  margin-right: 0.5em;
`;
export const PataIcon = styled(GiMedicines)`
  margin-right: 0.5em;
`;
export const ProvIcon = styled(AiOutlineMedicineBox)`
  margin-right: 0.5em;
`;
export const LoginIcon = styled(BiLogInCircle)`
  margin-right: 0.5em;
`;
export const LogoutIcon = styled(BiLogOutCircle)`
  margin-right: 0.5em;
`;
export const RegIcon = styled(MdOutlineAssignmentInd)`
  margin-right: 0.5em;
`;
export const CloudSoftware = styled(GrCloudSoftware)`
  margin-right: 0.5em;
  color: #86c2a6;
`;
export const OutlineLogin = styled(AiOutlineLogin)`
  margin-right: 0.5em;
`;
export const Blog = styled(ImBlog)`
  margin-right: 0.5em;
`;
export const Tips = styled(MdOutlineTipsAndUpdates)`
  margin-right: 0.5em;
`;
export const About = styled(FcAbout)`
  margin-right: 0.5em;
`;
export const CallIcon = styled(BiPhoneCall)`
  margin-right: 0.5em;
`;
export const Hr = styled.hr`
  background-color: #1d9683;
  border: none;
  height: 1px;
  width: 80%;
`;
export const SidebarBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;
export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(0, 80px);
  text-align: center;
  ${mobileS({
    margin: '0.5em',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    gridTemplateRows: ' repeat(5, 60px)',
  })}
  ${mobileM({
    margin: '0',
    padding: '0',
    gridTemplateRows: ' repeat(6, 60px)',
  })}
  @media screen and (min-width: 600px) {
    grid-template-rows: repeat(6, 60px);
  }
`;
export const SidebarRoute = styled(Link)`
  text-decoration: none;
  color: #86c2a6;
  @media screen and (min-width: 768px) {
    border-bottom: 1px solid #86c2a6;
    width: 50%;
    align-self: center;
    justify-self: center;
    padding: 12px 0;
  }
`;
