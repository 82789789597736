import React from 'react';
import styled from 'styled-components';
import bannerImage from './banner 2.png'; 
import { Link } from 'gatsby'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import Med from '../med/Med';
import Next from './right-arrow.svg'
import Prev from './left-arrow.svg'
 





function HomepageBanner() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <img
          src={Prev}
          alt="prevArrow"
          {...props}
          style={{ height: '40px', width: '40px' }}
        />
      )
    
      const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <img
          src={Next}
          alt="nextArrow"
          {...props}
          style={{ height: '40px', width: '40px' }}
        />
      )
      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        lazyLoad: 'anticipated',
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              dots: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: false,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 375,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      }

      const data2 = useStaticQuery(graphql`
      query MyQuery {
        allProducts {
          edges {
            node {
              id
              productName
              batchNumber
              category
              sellingPrice
              customBrandName
              expiry
              formulation
              genericName
              packSize
              prescribed
              strength
              supplier
              showProduct
              showPrice
              type
              unit
              productImage
              score
              discount {
                type
                value
              }
              shop {
                name
                physicalAddress {
                  county
                  street
                }
              }
            }
          }
        }
      }
    `)
  
    const productsR = data2.allProducts.edges.map(item => {
      return item.node
    })
  
    //const products = productsR.filter((product)=>product.shop.name.toLowerCase()==="pharmilymed")
  
    const preferredShop = process.env.GATSBY_PREFERRED_SHOP
  
    const products = productsR?.sort((a, b) => {
      if (a.shop._id === preferredShop) return -1
      if (b.shop._id === preferredShop) return 1
      return 0
    })
  
    
  return (
    <>
      <BannerContainer>
        <Link to="/hygiene">
          <BannerImage
            src={bannerImage}
            alt="Homepage Banner"
            style={{ cursor: 'pointer' }}
          />
        </Link>
      </BannerContainer>
      <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
        <div className="slideTitle">
          <div className="saleContainer">
            <span className="heading">Top Products</span>
          </div>
          <span className="seeall">
            <TopButton to="/hygiene">More</TopButton>
          </span>
        </div>
        <ProductSlideWrapper className="topProducts">
          <Slider {...settings}>
            {products.map(product => {
              return (
                product.showProduct &&
                !product.prescribed &&
                product.category.includes('hygiene') && (
                  <Med products={product} />
                )
              )
            })}
          </Slider>
        </ProductSlideWrapper>
      </div>
    </>
  )
}

export default HomepageBanner;

const ProductSlideWrapper = styled.div`
  background: white;
  padding: 1em 1.5em;
  margin: 1em 0;
  width: 97%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 9%);
`

const TopButton = styled(Link)`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 8px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 15px;
  outline: 2.5px solid #1d9682;
  border: none;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2sease-in-out;
    background: #2e4158;
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }
`

const BannerContainer = styled.div`
  width: 100%;
  height: 150px; 
  overflow: hidden;
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%; 
  object-fit: cover; 
  display: block;
  overflow: hidden;
  cursor: pointer;
`;