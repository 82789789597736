import React from "react"
import styled from "styled-components"


const AboutContainer = styled.div`
  padding: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 320px) {
    padding: 1em;
  }
  @media screen and (min-width: 425px) {
    padding: 1em;
  }
`
const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  width: 31%;
  padding: 48px;
  @media screen and (min-width: 320px) {
    width: 100%;
    padding: 0;
    border: none;
    border-radius: 0;
    background: none;
  }
  @media screen and (min-width: 425px) {
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;
  }
  @media screen and (min-width: 768px) {
    width: 80%;
    border: 2px solid #1d9683;
    border-radius: 15em 0 15em 0;
    background-color: #73747316;
  }
  @media screen and (min-width: 1024px) {
    width: 70%;
    border: 2px solid #1d9683;
    border-radius: 15em 0 15em 0;
    background-color: #73747316;
  }
  @media screen and (min-width: 1440px) {
    width: 60%;
    border: 2px solid #1d9683;
    border-radius: 15em 0 15em 0;
    background-color: #73747316;
  }
`
const Hr = styled.hr`
  background-color: #1d9683;
  border: none;
  height: 1px;
  width: 80%;
  @media screen and (min-width: 768px) {
    width: 20%;
  }
  @media screen and (min-width: 1024px) {
    width: 60%;
  }
  @media screen and (min-width: 1440px) {
    height: 2.5px;
    width: 60%;
  }
`
const Header = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 400;
  font-size: 3.5em;
  line-height: 1.5em;
  color: #1d9683;
  @media screen and (min-width: 320px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 425px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 1440px) {
    font-size: 3em;
  }
`

const Text = styled.p`
  font-family: Oswald;
  font-size: 2.3em;
  font-weight: 300;
  line-height: 1.5em;
  text-align: center;
  width: 900px;
  margin-top: 20px;
  @media screen and (min-width: 320px) {
    font-size: 1.6em;
    line-height: 1.7em;
    width: 90%;
  }
  @media screen and (min-width: 425px) {
    font-size: 1.5em;
    line-height: 1.5em;
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.5em;
    line-height: 1.5em;
    width: 90%;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.6em;
    line-height: 1.5em;
    width: 20em;
  }
  @media screen and (min-width: 1440px) {
    font-size: 1.8em;
    line-height: 2em;
    width: 90%;
  }
`

export default function MtmBenefits() {
  return (
    <AboutContainer id="benefits">
      <AboutWrapper>
        <Header>Benefits </Header>
        <Hr />
        <Text>
          Access medication and medical providers from the comfort of your home, at any time and from any location.
        </Text>
        <Hr />        
      </AboutWrapper>
    </AboutContainer>
  )
}
