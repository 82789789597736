import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import axios from "axios"
import {
  ProvContainer,
  ProvWrapper,
  TextCont,
  Header,
  Line,
  Desc,
  DescUl,
  DetailList,
  ProvBtnLink,
  HeaderWrapper,
  ModalHeader,
  Subtitle,
  StyledForm,
  StyledInput,
  StyledButton,
  ReadMoreBtn,
  StyledIcon,
  SuccessImg,
  SuccessContainer,
  SuccessHeader,
  SuccessText,
  SuccessSubtitle,
} from "./providerElements"
import HeroSuccess from "../../sections/service-section/images/thankyou.svg"
import useAuthState from "../../stores/auth"
const ProviderSection = ({
  id,
  headline,
  description,
  detailList,
  buttonLabel,
  buttonLabel1,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [show, setShow] = useState(false)

  //success modal state
  const close = () => setModalOpen(false)
  const open = () => setModalOpen(true)

  const handleClose = () => {
    setShow(false)
    setSuccess("")
    setError("")
  }
  const handleShow = () => setShow(true)

  const { user } = useAuthState()

  const [name, setName] = useState(
    user?.firstName
      ? user.firstName
      : "" + "" + user?.lastName
      ? user.lastName
      : ""
  )
  const [email, setEmail] = useState(user?.email || "")

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const uploadData = async () => {
    try {
      setError("")
      setSuccess("")
      if (!name || !email) {
        setError("Missing required fields")
      } else {
        setLoading(true)

        const data = {
          name,
          email,
          type: "provider",
          //change type for each page
        }
        await axios.post(`${process.env.GATSBY_API_URL}/registrations/`, {
          ...data,
        })
        //uncomment later on

        setSuccess("Success")
        setLoading(false)
        setSubmitted(true)
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error",
        err
      )
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  return (
    <ProvContainer id={id}>
      <ProvWrapper>
        <TextCont style={{ background: "#027c68" }}>
          <Header>{headline}</Header>
          <Line />
          <Desc>{description}</Desc>
          <DescUl>
            {detailList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DetailList>{item}</DetailList>
                  <Line />
                </React.Fragment>
              )
            })}
          </DescUl>
          <ProvBtnLink style={{ background: "#2e4158" }} onClick={handleShow}>
            {buttonLabel}
          </ProvBtnLink>
          <ReadMoreBtn style={{ color: "#fff" }} to="/providersMed">
            {buttonLabel1}...
          </ReadMoreBtn>
          <Modal
            show={show}
            onHide={handleShow}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledForm>
              {success && (
                <>
                  <SuccessContainer>
                    <StyledIcon onClick={handleClose} />
                    <SuccessImg src={HeroSuccess} />
                    <SuccessHeader>Welcome onboard, Dr.{name}</SuccessHeader>
                    <SuccessText>Thank you for joining</SuccessText>
                    <SuccessSubtitle>Afyabook for Providers</SuccessSubtitle>
                  </SuccessContainer>
                </>
              )}
              {!success && (
                <>
                  <StyledIcon onClick={handleClose} />
                  <HeaderWrapper>
                    <ModalHeader>
                      Increase revenue while cutting costs and saving time
                    </ModalHeader>
                    <Subtitle>
                      Providing providers with customers as well as the tools
                      they need to increase revenue while lowering costs
                    </Subtitle>
                  </HeaderWrapper>
                  <label htmlFor="name">Name</label>
                  <StyledInput
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => {
                      setName(e.target.value)
                    }}
                  />
                  <label htmlFor="email">Email</label>
                  <StyledInput
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                    }}
                  />
                  {loading && (
                    <p style={{ color: "green", fontSize: "1.2rem" }}>
                      Loading...
                    </p>
                  )}
                  <StyledButton
                    disabled={submitted || loading || !name || !email}
                    onClick={() =>
                      uploadData() && (modalOpen ? close() : open())
                    }
                  >
                    Send Message
                  </StyledButton>{" "}
                </>
              )}
            </StyledForm>
          </Modal>
        </TextCont>
      </ProvWrapper>
    </ProvContainer>
  )
}
export default ProviderSection
