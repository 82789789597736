import React from "react"
import {
  ImgCont,
  WhyContainer,
  WrapperAd,
} from "../provider-section/styles/whyNow"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function AdCard() {
  return (
    <WhyContainer>
      <WrapperAd >
        <Link to="/userprofile">
        <div style={{margin:"10px"}}>
          <StaticImage height={500} src="https://res.cloudinary.com/tripleaim-software/image/upload/v1728385527/afyabook%20images/vitals_4_1_uo7ffu.png" alt="remote patient monitoring" placeholder="blurred" imgStyle={{ borderRadius: "20px" }} />
        </div>
        </Link>
        <Link to="/programs">
        <div style={{margin:"10px"}}>
          <StaticImage height={500} src="https://res.cloudinary.com/tripleaim-software/image/upload/v1728385926/afyabook%20images/wellness_1_gdcswf.png" alt="Upload prescription" placeholder="blurred" imgStyle={{ borderRadius: "20px" }}/>
        </div>    
        </Link>
      </WrapperAd>
    </WhyContainer>
  )
}
