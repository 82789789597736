export const providerDetailsPharmacy = {
  id: 'service',
  headline: 'Afyabook for Pharmacy',
  description: 'Are you a pharmacy interested in reaching new patients?',
  detailList: [
    'Reach patients in your area looking for medication and pharmacy services',
    'Refill prescriptions and fill new prescriptions and quotations',
    'Strengthen your online reputation with verified reviews',
  ],
  buttonLabel: 'List your Pharmacy on Afyabook',
  buttonLabel1: 'Read more',
};
