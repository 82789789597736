import React, { useState } from "react"
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  PataIcon,
  HomeIcon,
  ProvIcon,
  CallIcon,
  RegIcon,
  LogoutIcon,
  OutlineLogin,
  Blog,
  About,
  Tips,
} from "./SideBarElements"
import { navigate } from "gatsby"
import useAuthState from "../../stores/auth"

const SideBar = ({ isOpen, toggle }) => {
  const { isLoggedIn, logOut } = useAuthState()
  const logout = () => {
    logOut()
    navigate("/")
  }
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <SidebarWrapper>
        <Icon>
          <CloseIcon />
        </Icon>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            <HomeIcon />
            Home
          </SidebarLink>
          <SidebarLink to="/aboutUs" onClick={toggle}>
            <About />
            About us
          </SidebarLink>
          <SidebarLink to="/blog" onClick={toggle}>
            <Blog />
            Blog
          </SidebarLink>
          {/* <SidebarLink
            to="https://www.healthtuition.com/"
            target="_blank"
            onClick={toggle}
          >
            <Tips />
            Health Tips
          </SidebarLink> */}
          {/* <SidebarLink to="/" onClick={toggle}>
            Book Now
          </SidebarLink> */}
          {isLoggedIn() ? (
            <>
              <SidebarLink to="/userprofile" onClick={toggle}>
                <RegIcon />
                Profile
              </SidebarLink>
              <SidebarLink to="/" onClick={logout}>
                <LogoutIcon />
                Logout
              </SidebarLink>
            </>
          ) : (
            <SidebarLink to="/Login">
              <OutlineLogin />
              Login
            </SidebarLink>
          )}
          <SidebarLink to="/providers" onClick={toggle}>
            <ProvIcon />
            For Pharmacy
          </SidebarLink>
          <SidebarLink to="/providersMed" onClick={toggle}>
            <ProvIcon />
            For Clinic
          </SidebarLink>
          <SidebarLink
            to="https://www.patameds.com/"
            target="_blank"
            onClick={toggle}
          >
            <PataIcon />
            Wholesale
          </SidebarLink>
          <SidebarLink
            to="https://afyabook-pro.com"
            target="_blank"
            onClick={toggle}
          >
            <ProvIcon />
            Afyabook Pro
          </SidebarLink>
          <SidebarLink>
            <CallIcon />
            <a href="tel://+254 792 640 973">+254 792 640 973</a>
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}
export default SideBar
