import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Link } from 'gatsby';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import imageOne from './indexhero2.png';
import imageTwo from './indexheroc.webp';

const HeroContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 0;
  min-height: 50vh;
  overflow: hidden;
`;

const HeroContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
`;

const HeroItems = styled.div`
  display: block;
  text-align: center;
  height: 100%;
  padding: 1rem;
  width: 30em;
  color: #000;
`;

const HeroH1 = styled.h1`
  font-size: clamp(1.2rem, 2vw, 2.5rem);
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
`;

const HeroP = styled.p`
  font-size: clamp(1rem, 2vw, 1.5rem);
  padding: 0 0.5rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  color: #000;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Button = styled(Link)`
  background: #71C4DE;
  white-space: nowrap;
  padding: 10px;
  margin: 2px;
  color: white;
  font-size: 20px;
  outline: none;
  border: 2px solid #71C4DE;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: 10px;
  margin-bottom: 1rem;

  &:hover {
    text-decoration-line: none;
  }
`;

const HeroImage = styled.div`
  max-width: 450px;
  min-width: 200px;
  border-radius: 30%;
`;

const HeroCarousel = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  //const images = [imageOne, imageTwo];
  const images = ["https://res.cloudinary.com/tripleaim-software/image/upload/v1728630287/afyabook%20images/docimg_qpn0gs.png",
                  "https://res.cloudinary.com/tripleaim-software/image/upload/v1728630786/afyabook%20images/pharma_1_zok6e8.png",
                  "https://res.cloudinary.com/tripleaim-software/image/upload/v1728630954/afyabook%20images/labs_1_ymhsht.png",
                  "https://res.cloudinary.com/tripleaim-software/image/upload/v1728631300/afyabook%20images/tel_1_jwkqnc.png"];

  return (
    <HeroContainer>
      <HeroContent>
        <HeroItems>
          <HeroH1>What Matters to You?</HeroH1>
          <HeroP>
            Need medication or consultation with a doctor near your location?
          </HeroP>
          <HeroP>Afyabook is ready for you anytime</HeroP>
          <Buttons>
            <Button to="/pharmacy">Browse Products</Button>
            <Button to="/upload-prescription">Upload prescription</Button>
          </Buttons>
        </HeroItems>

        <HeroImage>
          <Slider {...settings}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Afyabook online doctor ${index + 1}`}
                style={{ width: '100%', height: 'auto' }}
              />
            ))}
          </Slider>
        </HeroImage>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroCarousel;
